import Link from 'next/link';
import React, { ReactElement } from 'react';

import { BigCommerceCheckoutButton } from '@components/Button/BigCommerceCheckoutButton';
import { SkipTo } from '@components/SkipTo/SkipTo';

export function ShopBy(): ReactElement {
  return (
    <div className="m-0 hidden min-h-7 bg-grey-dark text-center font-secondary uppercase tracking-wider text-white xl:block">
      <nav className="mx-auto my-0 max-w-maxWidthMenu">
        <ul className="m-0 hidden list-none justify-between gap-4 p-0 tiny:flex">
          <li className="px-2.5 py-2 text-[1.10rem] text-grey-mid">Shop by:</li>
          <li className="group px-2.5 py-1 text-[1.10rem] hover:bg-white hover:no-underline dark:hover:bg-grey-light">
            <Link
              prefetch={process.env.NEXT_PUBLIC_PREFETCH === 'true'}
              href="/categories/shop-by-sport"
              className="block px-2.5 py-1 text-center text-[1.10rem] text-white no-underline group-hover:text-grey-dark dark:hover:text-grey-dark"
            >
              Sport
            </Link>
            <SkipTo pathToBeAccessed="/categories/shop-by-sport" />
          </li>
          <li className="group px-2.5 py-1 text-[1.10rem] hover:bg-white hover:no-underline dark:hover:bg-grey-light">
            <Link
              prefetch={process.env.NEXT_PUBLIC_PREFETCH === 'true'}
              href="/categories/shop-by-ingredient"
              className="block px-2.5 py-1 text-center text-white no-underline group-hover:text-grey-dark dark:hover:text-grey-dark"
            >
              Ingredient
            </Link>
            <SkipTo pathToBeAccessed="/categories/shop-by-ingredient" />
          </li>
          <li className="group px-2.5 py-1 text-[1.10rem] hover:bg-white hover:no-underline dark:hover:bg-grey-light">
            <Link
              prefetch={process.env.NEXT_PUBLIC_PREFETCH === 'true'}
              href="/categories/shop-by-flavour"
              className="block px-2.5 py-1 text-center text-white no-underline group-hover:text-grey-dark dark:hover:text-grey-dark"
            >
              Flavour
            </Link>
            <SkipTo pathToBeAccessed="/categories/shop-by-flavour" />
          </li>
          <li className="group px-2.5 py-1 text-[1.10rem] hover:bg-white hover:no-underline dark:hover:bg-grey-light">
            <Link
              prefetch={process.env.NEXT_PUBLIC_PREFETCH === 'true'}
              href="/goal"
              className="block px-2.5 py-1 text-center text-white no-underline group-hover:text-grey-dark dark:hover:text-grey-dark"
            >
              Goal
            </Link>
            <SkipTo pathToBeAccessed="/goal" />
          </li>
          <li className="group px-2.5 py-1 text-[1.10rem] hover:bg-white hover:no-underline dark:hover:bg-grey-light">
            <Link
              prefetch={process.env.NEXT_PUBLIC_PREFETCH === 'true'}
              href="/categories/shop-by-diet"
              className="block px-2.5 py-1 text-center text-white no-underline group-hover:text-grey-dark dark:hover:text-grey-dark"
            >
              Diet
            </Link>
            <SkipTo pathToBeAccessed="/categories/shop-by-diet" />
          </li>
          <li className="group px-2.5 py-1 text-[1.10rem] hover:bg-white hover:no-underline dark:hover:bg-grey-light">
            <Link
              prefetch={process.env.NEXT_PUBLIC_PREFETCH === 'true'}
              href="/categories/shop-by-recommended"
              className="block px-2.5 py-1 text-center text-white no-underline group-hover:text-grey-dark dark:hover:text-grey-dark"
            >
              Recommended
            </Link>
            <SkipTo pathToBeAccessed="/categories/shop-by-recommended" />
          </li>
          <li className="group px-2.5 py-1 text-[1.10rem] hover:bg-white hover:no-underline dark:hover:bg-grey-light">
            <Link
              prefetch={process.env.NEXT_PUBLIC_PREFETCH === 'true'}
              href="/express"
              className="block px-2.5 py-1 text-center text-white no-underline group-hover:text-grey-dark dark:hover:text-grey-dark"
            >
              Express
            </Link>
            <SkipTo pathToBeAccessed="/express" />
          </li>

          {/* This li grows to fill the space between */}
          <li className="grow text-grey-mid" />

          <li className="group px-2.5 py-1 text-[1.10rem] hover:bg-white hover:no-underline dark:hover:bg-grey-light">
            <Link
              prefetch={process.env.NEXT_PUBLIC_PREFETCH === 'true'}
              href="/cart"
              className="block px-2.5 py-1 text-center text-white no-underline group-hover:text-grey-dark dark:hover:text-grey-dark"
            >
              Cart
            </Link>
            <SkipTo pathToBeAccessed="/cart" />
          </li>
          <li className="group px-2.5 py-1 text-[1.10rem] hover:bg-green hover:no-underline dark:hover:bg-grey-light">
            <BigCommerceCheckoutButton
              styledButton={false}
              className="block px-2.5 py-1 text-center text-white no-underline group-hover:text-white dark:hover:text-grey-dark"
            />
          </li>
        </ul>
      </nav>
    </div>
  );
}
