import Link from 'next/link';
import React, { ReactElement, useState } from 'react';
import { BsTrash } from 'react-icons/bs';

import { ErrorAlert } from '@components/Alerts/ErrorAlert';
import { Loading } from '@components/Alerts/Loading';
import { CartPrice } from '@components/Cart/CartPrice';
import { HorizontalDivider } from '@components/HorizontalDivider/HorizontalDivider';
import { CustomImage } from '@components/Image/Image';
import { Paragraph } from '@components/Typography/Paragraph/Paragraph';
import { TextLink } from '@components/Typography/TextLink/TextLink';
import { useRemoveItem } from '@hooks/cart/useRemoveItem';
import { DigitalItem, PhysicalItem } from '@interfaces/ICart';
import { CustomCartItem } from '@interfaces/ICart';
import { log } from '@lib/utils';

export function QuickCartItem({
  item,
  lineItem,
}: {
  item: CustomCartItem;
  lineItem: PhysicalItem | DigitalItem;
}): ReactElement {
  const removeItem = useRemoveItem({
    include: ['line_items.physical_items.options'],
  });
  const [updating, setUpdating] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState(
    <Paragraph>Unable to remove that item</Paragraph>
  );

  const handleRemove = async () => {
    setUpdating(true);

    try {
      const res = await removeItem({ id: lineItem.id });
      if (res.error) {
        setUpdating(false);
        setErrorMessage(<Paragraph>{res.error}</Paragraph>);
        setError(true);
      }
    } catch (error) {
      log(error);
      setUpdating(false);
      setErrorMessage(<Paragraph>Unable to remove that item</Paragraph>);
      setError(true);
    } finally {
      // in case of error, set back to false so opacity resets
      setUpdating(false);
    }
  };

  if (!item || !lineItem) return <div />;

  return (
    <div className={updating ? 'opacity-50' : ''}>
      <ErrorAlert show={error} setShow={setError}>
        {errorMessage}
      </ErrorAlert>
      <div className="grid grid-cols-[1fr_3fr] grid-rows-[min-content_1fr] px-0 py-4 md:grid-cols-[1fr_4fr]">
        <Link
          href={item.url}
          passHref
          prefetch={process.env.NEXT_PUBLIC_PREFETCH === 'true'}
        >
          <CustomImage image={item.image} width={85} height={85} />
        </Link>
        <div>
          <button
            type="button"
            aria-label="Remove item from cart"
            onClick={handleRemove}
            className="gae-remove-item float-right border-none bg-transparent px-2.5 py-1 uppercase text-grey-dark hover:text-orange dark:text-grey-light dark:hover:text-orange"
            data-product-id={item.productId}
            data-variant-id={item.variant.variantId}
            data-product-name={item.title}
            data-product-price={lineItem.sale_price}
            data-product-quantity={lineItem.quantity}
          >
            {updating ? (
              <Loading className="right-[10px] top-[-10px] h-[20px] w-[20px] scale-[.35]" />
            ) : (
              <BsTrash size={20} />
            )}
          </button>
          <div className="flex gap-1 py-2 leading-5">
            {item.variant.options?.filter(
              (o) => o.title === 'Package Size'
            )[0] && (
              <span>
                {
                  item.variant.options?.filter(
                    (o) => o.title === 'Package Size'
                  )[0].value
                }
              </span>
            )}
            <TextLink href={item.url} target="_top">
              {item.title}
            </TextLink>
            <span>
              {
                item.variant.options?.filter((o) => o.title === 'Flavour')[0]
                  ?.value
              }
            </span>
            {!item.variant.options
              ?.map((o) => o.title)
              .includes('Package Size') && (
              <span>{item.variant.options?.map((o) => o.value).join(' ')}</span>
            )}
          </div>
          <div className="grid grid-cols-[1fr_2fr_2fr] gap-1">
            <div className="flex flex-col font-secondary">
              <span className="text-s uppercase text-grey-mid">Quantity:</span>
              <span className="text-2xl">{lineItem.quantity}</span>
            </div>
            <CartPrice
              retail={item.price / 100}
              discounted={lineItem.sale_price as number}
              label="Unit Price:"
            />
            <CartPrice
              retail={(item.price / 100) * lineItem.quantity}
              discounted={lineItem.extended_sale_price as number}
              label="Total:"
            />
          </div>
        </div>
      </div>
      <HorizontalDivider className="m-0 via-grey-mid" />
    </div>
  );
}
