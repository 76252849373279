/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';

import { HorizontalDivider } from '@components/HorizontalDivider/HorizontalDivider';
import { TextLink } from '@components/Typography/TextLink/TextLink';
import { isErrorResponse } from '@interfaces/BulkAPI';
import { DigitalItem, PhysicalItem } from '@interfaces/ICart';
import { RelatedProduct } from '@interfaces/Product';

export function QuickCartFPT({
  cartItem,
}: {
  cartItem: DigitalItem | PhysicalItem;
}) {
  const [relatedProducts, setRelatedProducts] = useState<RelatedProduct[]>([]);

  useEffect(() => {
    // fetch data
    const dataFetch = async () => {
      const res = await (
        await fetch(`/api/cart/getRelatedProducts?id=${cartItem.product_id}`)
      ).json();

      if (isErrorResponse(res)) {
        setRelatedProducts([]);
      } else {
        setRelatedProducts(res.data);
      }
    };

    dataFetch();
  }, []);

  return relatedProducts && relatedProducts.length > 0 ? (
    <div>
      <div className="py-6 text-left">
        <p className="mb-0 pl-8">{cartItem.name} customers also purchased: </p>
        <ul data-testid="fpt-links" className="m-0 list-disc pl-12.5">
          {relatedProducts.slice(0, 3).map((item) => (
            <li data-testid="fpt-link" className="fpt-link" key={item.entityId}>
              <TextLink href={item.slug} target="_top" className="underline">
                {item.name}
              </TextLink>
            </li>
          ))}
        </ul>
      </div>
      <HorizontalDivider className="m-0 via-grey" />
    </div>
  ) : null;
}
