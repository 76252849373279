/**
 * This component is for the second line of the header
 * On desktop it contains logo, search section, and customer nav
 * On mobile it is hamburger for menu, logo, cart
 * Some of the mobile styles are done on the CustomerNav component (to handle showing cart but not other sections)
 */

import dynamic from 'next/dynamic';
import Link from 'next/link';
import React, { ReactElement, useContext } from 'react';
import { AiOutlineHeart } from 'react-icons/ai';
import { BiUser } from 'react-icons/bi';
import { BsArrowCounterclockwise, BsSearch } from 'react-icons/bs';
import { FiMenu } from 'react-icons/fi';
import { IoTrophyOutline } from 'react-icons/io5';

import { BulkErrorBoundary } from '@components/Alerts/ErrorBoundaryFallback';
import { Button } from '@components/Button';
import { ButtonStyle } from '@components/Button/Button';
import { CartItemsCountButton } from '@components/Button/CartItemsCountButton';
import { Container } from '@components/Container';
import {
  UIContext,
  UIContextInterface,
} from '@components/Context/UIContext/UIContext';
import { DarkMode } from '@components/DarkMode/DarkMode';
import { SkipTo } from '@components/SkipTo/SkipTo';
import { TooltipWrapper } from '@components/Tooltip/Tooltip';
import { useCustomer } from '@hooks/customer/useCustomer';

const AutocompleteDynamic = dynamic(() =>
  import('../../Search').then((mod) => mod.Autocomplete)
);

const ICON_SIZE = '1.75rem';

function CustomerNav(): ReactElement {
  const { customer } = useCustomer();
  const {
    displayCart: [quickCartIsOpen, setQuickCartIsOpen],
    recentlyViewed: { recentlyViewedIsOpen, setRecentlyViewedIsOpen },
  } = useContext<UIContextInterface>(UIContext);

  const handleCartEvent = () => {
    setQuickCartIsOpen(!quickCartIsOpen);
  };

  const handleRecentlyViewedEvent = () => {
    setRecentlyViewedIsOpen(!recentlyViewedIsOpen);
  };

  return (
    <div className="flex w-fit gap-0 sm:gap-4">
      <TooltipWrapper text="Favourite Products">
        <Button
          href="/account/favourites"
          aria-label="Favourite products"
          className="relative hidden w-10 rounded-full ring-grey-mid ring-offset-2 hover:scale-110
           hover:bg-white hover:text-black hover:ring-1 hover:ring-grey-mid dark:hover:bg-grey-dark dark:hover:text-white xl:flex"
          buttonStyle={ButtonStyle.tertiary}
          icon={<AiOutlineHeart size={ICON_SIZE} />}
        />
      </TooltipWrapper>
      <TooltipWrapper text="Rewards">
        <Button
          aria-label="Rewards"
          className="relative hidden w-10 rounded-full ring-grey-mid ring-offset-2 hover:scale-110
          hover:bg-white hover:text-black hover:ring-1 hover:ring-grey-mid dark:hover:bg-grey-dark dark:hover:text-white xl:flex"
          buttonStyle={ButtonStyle.tertiary}
          href="/rewards"
          icon={<IoTrophyOutline size={ICON_SIZE} />}
        />
      </TooltipWrapper>
      <DarkMode className="relative hidden rounded-full p-0 ring-grey-mid ring-offset-2 hover:scale-110 hover:bg-white hover:text-black hover:ring-1 hover:ring-grey-mid dark:hover:bg-grey-dark dark:hover:text-white xl:flex" />
      {customer && (
        <TooltipWrapper text="Your Account">
          <Button
            href="/account"
            aria-label="Your account"
            className="relative w-10  rounded-full ring-grey-mid ring-offset-2 hover:scale-110
            hover:bg-white hover:text-black hover:ring-1 hover:ring-grey-mid dark:hover:bg-grey-dark dark:hover:text-white"
            buttonStyle={ButtonStyle.tertiary}
            icon={<BiUser size={ICON_SIZE} />}
          />
        </TooltipWrapper>
      )}
      {!customer && (
        <TooltipWrapper text="Sign in">
          <Button
            href="/login"
            aria-label="Sign in"
            className="relative w-10 rounded-full ring-grey-mid ring-offset-2 hover:scale-110
            hover:bg-white hover:text-black hover:ring-1 hover:ring-grey-mid dark:hover:bg-grey-dark dark:hover:text-white"
            buttonStyle={ButtonStyle.tertiary}
            icon={<BiUser size={ICON_SIZE} />}
          />
        </TooltipWrapper>
      )}
      <TooltipWrapper text="Recent Products">
        <Button
          onClick={handleRecentlyViewedEvent}
          aria-label="Recent products"
          className="relative hidden w-10 rounded-full ring-grey-mid ring-offset-2 hover:scale-110
          hover:bg-white hover:text-black hover:ring-1 hover:ring-grey-mid dark:hover:bg-grey-dark dark:hover:text-white xl:flex"
          buttonStyle={ButtonStyle.tertiary}
          icon={<BsArrowCounterclockwise size={ICON_SIZE} />}
        />
      </TooltipWrapper>
      <CartItemsCountButton handleCartEvent={handleCartEvent} />
    </div>
  );
}

export function Quicklinks() {
  const {
    displayMenu: [menuIsOpen, setMenuIsOpen],
    displayDarkMode: [darkModeIsOn],
  } = useContext<UIContextInterface>(UIContext);

  const handleMenuEvent = () => {
    setMenuIsOpen(true);
  };

  const styles = {
    backgroundImage: darkModeIsOn
      ? "url('/images/header/bulk-nutrients-header-dark-icons.jpg')"
      : "url('/images/header/bulk-nutrients-header-icons.jpg')",
  };

  return (
    <div className="min-h-[76px] bg-grey-light dark:bg-black xl:min-h-[76px]">
      <Container>
        <div className="flex items-center justify-between gap-0 px-0 py-2.5 sm:relative xl:grid-cols-[200px_auto_320px]">
          {/* Menu and Search */}
          <div className="mx-0 my-0 flex items-center gap-0 tiny:gap-2 sm:mx-2.5 sm:gap-4">
            <div className="flex flex-col items-center text-[0.8rem] leading-3">
              <TooltipWrapper text="Menu">
                <Button
                  onClick={handleMenuEvent}
                  aria-label="Menu"
                  className="relative w-10 text-xs ring-grey-mid ring-offset-2 hover:scale-110 hover:rounded-full hover:bg-white hover:text-black hover:ring-1 hover:ring-grey-mid dark:hover:bg-grey-dark dark:hover:text-white"
                  buttonStyle={ButtonStyle.tertiary}
                  icon={<FiMenu size={ICON_SIZE} />}
                />
              </TooltipWrapper>
              Menu
            </div>
            <TooltipWrapper text="Search">
              <Button
                href="/search"
                aria-label="Search"
                className="relative w-10 text-xs ring-grey-mid ring-offset-2 hover:scale-110 hover:rounded-full hover:bg-white
                hover:text-black hover:ring-1 hover:ring-grey-mid dark:hover:bg-grey-dark dark:hover:text-white xl:hidden"
                buttonStyle={ButtonStyle.tertiary}
                icon={<BsSearch size={ICON_SIZE} />}
              />
            </TooltipWrapper>
          </div>

          {/* Logo */}
          <Link
            prefetch={process.env.NEXT_PUBLIC_PREFETCH === 'true'}
            href="/"
            style={styles as React.CSSProperties}
            aria-label="Bulk Nutrients logo"
            className="relative m-auto h-[75px] w-[150px] scale-70 bg-left-top p-0 mix-blend-multiply dark:mix-blend-normal tiny:scale-75 sm:scale-100 xl:m-0"
          />

          <SkipTo pathToBeAccessed="/" />

          {/* Desktop Search */}
          <BulkErrorBoundary location="Header/Quicklinks/AutocompleteDynamic">
            <div className="hidden xl:block">
              <AutocompleteDynamic />
            </div>
          </BulkErrorBoundary>

          {/* Quick Nav */}
          <CustomerNav />
        </div>
      </Container>
    </div>
  );
}
