import React, { ReactElement } from 'react';

export function CartPrice({
  retail,
  discounted,
  label = 'Price:',
}: {
  retail: number;
  discounted: number;
  label?: string;
}): ReactElement {
  if (Number.isNaN(retail) || Number.isNaN(discounted)) return <></>;

  if (retail === discounted) {
    return (
      <div className="flex flex-col font-secondary">
        <span className="text-s uppercase text-grey-mid">{label}</span>
        <span className="retail text-2xl font-bold">${retail.toFixed(2)}</span>
      </div>
    );
  }
  return (
    <div className="flex flex-col font-secondary">
      <span className="text-s uppercase text-grey-mid">{label}</span>
      <div className="flex flex-col gap-1">
        <span className="text-2xl line-through">${retail.toFixed(2)}</span>
        <span className="sale text-2xl font-bold">
          ${discounted.toFixed(2)}
        </span>
      </div>
    </div>
  );
}
