import { ReactNode } from 'react';

export function BannerH2({ children }: { children: ReactNode }) {
  return (
    <h2
      className="mb-4 ml-0 block w-full border-0 border-l-[15px] border-solid border-orange bg-grey-dark pb-1 pl-2.5 pr-5
      pt-1 font-secondary text-4xl font-normal uppercase text-white xl:-ml-2.5 xl:inline-block xl:w-fit"
    >
      {children}
    </h2>
  );
}
