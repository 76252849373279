/**
 * The entire header section, comprised of smaller components
 * MobileMenu is here because we want it to show up across the whole thing, but it's trigger
 * is in <Search/>, which is the hamburger icon is to appear
 */

import React, { ReactElement, useContext, useEffect } from 'react';

import { BulkErrorBoundary } from '@components/Alerts/ErrorBoundaryFallback';
import { QuickCart } from '@components/Cart/QuickCart/QuickCart';
import {
  UIContext,
  UIContextInterface,
} from '@components/Context/UIContext/UIContext';
import { Quicklinks } from '@components/Header/Quicklinks';
import { RecentlyViewed } from '@components/RecentlyViewed/RecentlyViewed';
import { useScroll } from '@hooks/useScroll';

import { Menu } from './Menu/Menu';

import { InfoNavbar } from '.';

function SlidingHeader() {
  const {
    headerHeight: [headerHeight, setHeaderHeight],
    headerScrollClass: [headerScrollClass, setHeaderScrollClass],
  } = useContext<UIContextInterface>(UIContext);

  const { x, y, lastX, lastY } = useScroll();

  useEffect(() => {
    let mounted = true;
    // get the height of the header on load
    // This function get header height and set it to state
    const getHeaderHeight = () => {
      const newHeight = (document.getElementById('header') as HTMLElement)
        .offsetHeight;

      setHeaderHeight(newHeight);
    };

    getHeaderHeight();

    const newClass =
      headerHeight && y > lastY && y > headerHeight ? 'hide' : 'show';

    if (mounted && newClass !== headerScrollClass)
      setHeaderScrollClass(newClass);

    return function cleanup() {
      mounted = false;
    };
  }, [
    x,
    y,
    lastX,
    lastY,
    headerScrollClass,
    headerHeight,
    setHeaderHeight,
    setHeaderScrollClass,
  ]);

  return (
    <header
      id="header"
      className={`${headerScrollClass} full-bleed relative left-0
      right-0 top-0 z-2 min-h-[99px] transition-all
      duration-200 ease-in-out [&.hide]:sticky
      [&.hide]:-translate-y-full [&.show]:sticky [&.show]:translate-y-0
      `}
    >
      <BulkErrorBoundary location="Header/InfoNavbar">
        <InfoNavbar />
      </BulkErrorBoundary>

      <BulkErrorBoundary location="Header/Quicklinks">
        <Quicklinks />
      </BulkErrorBoundary>
    </header>
  );
}

export function Header(): ReactElement {
  return (
    <BulkErrorBoundary location="Header">
      <QuickCart />
      <RecentlyViewed />
      <Menu />
      <SlidingHeader />
    </BulkErrorBoundary>
  );
}
