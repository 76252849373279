import { ReactElement } from 'react';

import { Paragraph } from '@components/Typography/Paragraph/Paragraph';
import { TextLink } from '@components/Typography/TextLink/TextLink';

export function EmptyCartMessage(): ReactElement {
  return (
    <div data-testid="empty-cart" className="mx-auto my-0 max-w-[360px]">
      <Paragraph>Uh oh! Your cart is empty 😢</Paragraph>
      <Paragraph>One of these products might be what you're after...</Paragraph>
      <ul>
        <li>
          <h3>
            <TextLink href="/products/whey-protein-isolate">
              Whey Protein Isolate
            </TextLink>
          </h3>
        </li>
        <li>
          <h3>
            <TextLink href="/products/whey-protein-concentrate">
              Whey Protein Concentrate
            </TextLink>
          </h3>
        </li>
        <li>
          <h3>
            <TextLink href="/products/earth-protein">Earth Protein</TextLink>
          </h3>
        </li>
        <li>
          <h3>
            <TextLink href="/products/hcp">Collagen Protein</TextLink>
          </h3>
        </li>
        <li>
          <h3>
            <TextLink href="/products/protein-bar-single">Protein Bar</TextLink>
          </h3>
        </li>
      </ul>
    </div>
  );
}
