import { useRouter } from 'next/router';
import React, { ReactElement } from 'react';

/**
 *
 * @param skipToHref Optionally specify the href anchor to skip focus to. Default is main-content
 * @param nameOfTarget Optionally specify a name or word to display in the skip to message. Default is main content
 * @param pathToBeAccessed Optionally specify pathname where the skip link will be active. If not specified skip link will always be rendered
 * @returns
 */
export function SkipTo({
  skipToHref = '#main-content',
  nameOfTarget = 'main content',
  pathToBeAccessed = null,
}: {
  skipToHref?: string;
  nameOfTarget?: string;
  pathToBeAccessed?: string | null;
}): ReactElement {
  const router = useRouter();

  // If pathToBeAccessed is not provided and therefore null, then by default skip link to be shown all the time
  // but if a path is provided, lets check if the current page matches and we can then have the skip link usable
  const skipLinkActive = pathToBeAccessed
    ? router.pathname === pathToBeAccessed || router.asPath === pathToBeAccessed
    : true;

  if (skipLinkActive) {
    return (
      <a
        href={skipToHref}
        className="skip-link focus:top:auto active:top:auto absolute z-3
      -m-[1px] inline-block h-[1px] w-[1px] overflow-hidden whitespace-nowrap border-0 p-0
      [clip:rect(0_0_0_0)] [word-wrap:normal] focus:left-[auto] focus:m-auto focus:h-auto
      focus:w-auto focus:bg-black focus:px-4 focus:py-1 focus:text-center focus:text-lg focus:font-light
      focus:text-white focus:underline
      focus:[clip:auto] active:left-[auto] active:m-auto active:h-auto active:w-auto
      active:bg-black active:px-4 active:py-1 active:text-center active:text-lg active:font-light active:text-white
      active:underline active:[clip:auto]"
      >
        Skip to {nameOfTarget} (Press enter)
      </a>
    );
  }

  return <></>;
}
