import { useEffect, useState } from 'react';

import { Paragraph } from '@components/Typography/Paragraph/Paragraph';
import { TextLink } from '@components/Typography/TextLink/TextLink';
import { useCustomer } from '@hooks/customer/useCustomer';

export function CreateAccountLoyaltyPrompt({
  cartValue,
}: {
  cartValue: number;
}) {
  const { customer } = useCustomer();
  const [tier, setTier] = useState('welcome');

  useEffect(() => {
    setTier(customer?.loyaltyLion?.tier ?? 'welcome');
  }, [customer]);

  let pointsPerDollar = 3;
  switch (tier) {
    case 'welcome':
      pointsPerDollar = 3;
      break;
    case 'bronze':
      pointsPerDollar = 4;
      break;
    case 'silver':
      pointsPerDollar = 4;
      break;
    case 'gold':
      pointsPerDollar = 5;
      break;

    default:
      break;
  }

  if (customer) {
    return (
      <Paragraph align="center">
        You're set to earn {Math.floor(pointsPerDollar * cartValue)} points
        after placing this order!
      </Paragraph>
    );
  } else {
    return (
      <Paragraph align="center">
        <TextLink href="/create-account">Create an account</TextLink> so you can
        earn {Math.floor(pointsPerDollar * cartValue)} points on this order!
      </Paragraph>
    );
  }
}
