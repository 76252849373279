import React, { useContext, useState } from 'react';

import { AddToCartStatus } from '@components/Button/AddToCart';
import {
  UIContext,
  UIContextInterface,
} from '@components/Context/UIContext/UIContext';
import { CustomImage } from '@components/Image/Image';
import { useAddItem } from '@hooks/cart/useAddItem';
import { UpsellItem } from '@interfaces/ICart';
import { log } from '@lib/utils';

export function QuickCartUpsell({
  upsellItem,
  showUpsellBanner,
}: {
  upsellItem: UpsellItem | undefined;
  showUpsellBanner: boolean;
}) {
  const {
    addingToCart: [addingToCartStatus, setAddingToCartStatus],
  } = useContext<UIContextInterface>(UIContext);
  const [showConfirmation, setShowConfirmation] = useState(false);

  // Add upsell item to cart
  const addItem = useAddItem({
    include: ['line_items.physical_items.options'],
  });
  const addToCart = async () => {
    setAddingToCartStatus(AddToCartStatus.Adding);
    try {
      if (!upsellItem) throw new Error('Upsell item is undefined');
      await addItem({
        lineItem: {
          productId: upsellItem.variant.productId,
          variantId: upsellItem.variant.variantId,
          quantity: 1,
        },
      });
      setAddingToCartStatus(AddToCartStatus.Added);
      // show confirmation message after adding upsell item to cart
      setShowConfirmation(true);
      setTimeout(() => setShowConfirmation(false), 6000);
    } catch (err) {
      setAddingToCartStatus(AddToCartStatus.Failed);
      log(err);
    }
  };

  // return empty div when upsell item does not exist
  if (!upsellItem) return <></>;

  return (
    <div>
      <div
        className={`${
          showConfirmation ? 'animate-[fadeInFromLeft.25s_forwards]' : 'closed'
        } 
        absolute z-3 -ml-2.5 w-full -translate-x-full -translate-y-[100px] bg-green
        p-6 text-center text-white opacity-0`}
      >
        <h3 className="italic">Brilliant stuff!</h3>
        <p className="m-0">{upsellItem?.message}</p>
      </div>
      {showUpsellBanner && !showConfirmation && (
        <button
          type="button"
          onClick={addToCart}
          className="cart-upsell-item mx-0 my-2.5 w-full border-none bg-transparent p-0"
        >
          <CustomImage image={upsellItem.image} width={500} />
        </button>
      )}
    </div>
  );
}
