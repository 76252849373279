/* eslint-disable @typescript-eslint/no-floating-promises */
import { useRouter } from 'next/router';
import React, { ReactElement, useContext, useEffect, useState } from 'react';

import { BulkErrorBoundary } from '@components/Alerts/ErrorBoundaryFallback';
import {
  UIContext,
  UIContextInterface,
} from '@components/Context/UIContext/UIContext';
import { ProductCard } from '@components/ProductCard';
import { SlideInPanel } from '@components/SlideInPanel/SlideInPanelNoPortal';
import { BannerH2 } from '@components/Typography/Headings/headings';
import { LocalStorageProduct } from '@interfaces/LocalStorage';
import {
  deleteIndexedDBData,
  getIndexedDBStoreData,
  Stores,
} from '@lib/localData';
import { logBreadcrumb } from '@lib/utils';

export function RecentlyViewed(): ReactElement {
  const [error, setError] = useState(false);
  const [items, setItems] = useState<LocalStorageProduct[]>([]);
  const {
    recentlyViewed: {
      recentlyViewedIsOpen,
      setRecentlyViewedIsOpen,
      recentlyViewedUpdates,
    },
  } = useContext<UIContextInterface>(UIContext);

  const router = useRouter();

  // close the recentlt view on route change
  useEffect(() => {
    const handleRouteChange = (url, shallow) => {
      setRecentlyViewedIsOpen(false);
    };

    router.events.on('routeChangeStart', handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, []);

  // get local storage
  useEffect(() => {
    let mounted = true;

    const setLocalItems = async () => {
      try {
        const localItems = await getIndexedDBStoreData<LocalStorageProduct[]>(
          Stores.keyvaluepairs,
          'recentlyViewed'
        );

        if (localItems) {
          // Should be fine, but if one of the products is archived or missing available dates, it will fail type check
          try {
            if (mounted) setItems(localItems);
          } catch (err) {
            console.error(err);
            await deleteIndexedDBData(Stores.keyvaluepairs, 'recentlyViewed');
            if (mounted) setItems([]);
          }
        } else if (mounted) setItems([]);
      } catch (error) {
        logBreadcrumb({
          category: 'IndexedDB',
          message: `Tried to get recently viewed products`,
          level: 'error',
        });
        setError(true);
      }
    };

    setLocalItems();

    return function cleanup() {
      mounted = false;
    };
  }, [items.length, recentlyViewedUpdates]);

  if (error) {
    return (
      <SlideInPanel
        isOpen={recentlyViewedIsOpen}
        toggleState={setRecentlyViewedIsOpen}
      >
        <div>Error loading recent items</div>
      </SlideInPanel>
    );
  }
  if (!items || items.length === 0) {
    return (
      <SlideInPanel
        isOpen={recentlyViewedIsOpen}
        toggleState={setRecentlyViewedIsOpen}
      >
        <div>No recent items</div>
      </SlideInPanel>
    );
  }

  return (
    <SlideInPanel
      isOpen={recentlyViewedIsOpen}
      toggleState={setRecentlyViewedIsOpen}
    >
      <section className="flex flex-col">
        <BannerH2>Recently Viewed Products</BannerH2>
        <div className="grid grid-cols-1 gap-5">
          <BulkErrorBoundary location="Recently viewed product cards">
            {items.map((item, i) => (
              <ProductCard product={item.product} key={`recentItem-${i}`} />
            ))}
          </BulkErrorBoundary>
        </div>
      </section>
    </SlideInPanel>
  );
}
